const server = process.env.APP_SERVER || "103brest.e-med.by";

const config = {
	server: `https://${server}`,
	api: `https://${server}/api`,
	ws: `wss://${server}/ws/`,
	ws_local: `ws://localhost:7777`,
	menu: 'cd374b9a-d775-40bf-8850-3695b2930a08'
};

export { config };
